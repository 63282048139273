import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useMemo } from 'react';
import { Checkbox, CheckboxSize, CheckboxGroup, CheckboxGroupOption, Icon, IconNavigationLocation, IconSize } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Checkbox, CheckboxGroup } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=679%3A183'} storybook={'/?path=/story/inputs-checkbox--playground'} vue={'/components/HseCheckbox/HseCheckbox.html'} vueStorybook={'/?path=/story/inputs-checkbox--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Checkbox – компонент для выбора одного или нескольких вариантов из списка.
Checkbox также может использоваться для переключения между состояниями "Включить" и "Выключить".`}</p>
    <p>{`Для передачи чекбоксу подписи, используйте проп `}<inlineCode parentName="p">{`label`}</inlineCode>{`.
Для управлением выбранным состоянием используйте проп `}<inlineCode parentName="p">{`checked`}</inlineCode>{`.`}</p>
    <p>{`Для объединения чекбоксов в группу используйте компонент `}<a parentName="p" {...{
        "href": "#checkboxgroup"
      }}>{`CheckboxGroup`}</a>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [checked, setChecked] = useState(false)\n  return (\n    <>\n      <Checkbox\n        checked={checked}\n        onChange={setChecked}\n        label=\"Подписаться на новостную рассылку\"\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [checked, setChecked] = useState(false);
        return <>
        <Checkbox checked={checked} onChange={setChecked} label="Подписаться на новостную рассылку" mdxType="Checkbox" />
      </>;
      }}
    </Playground>
    <Playground __position={2} __code={'() => {\n  const [itemsChecked, setItemsChecked] = useState([false, false, false])\n  const itemsLabels = [\n    \'Вариант номер один\',\n    \'Вариант номер два\',\n    \'Вариант номер три\',\n  ]\n  const createHandleItemChange = i => () => {\n    setItemsChecked(itemsChecked =>\n      itemsChecked.map((item, itemI) => (i === itemI ? !item : item)),\n    )\n  }\n  return (\n    <>\n      {itemsChecked.map((item, iItem) => (\n        <div key={iItem}>\n          <Checkbox\n            checked={item}\n            label={itemsLabels[iItem]}\n            onChange={createHandleItemChange(iItem)}\n          />\n        </div>\n      ))}\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [itemsChecked, setItemsChecked] = useState([false, false, false]);
        const itemsLabels = ['Вариант номер один', 'Вариант номер два', 'Вариант номер три'];

        const createHandleItemChange = i => () => {
          setItemsChecked(itemsChecked => itemsChecked.map((item, itemI) => i === itemI ? !item : item));
        };

        return <>
         {itemsChecked.map((item, iItem) => <div key={iItem}>
             <Checkbox checked={item} label={itemsLabels[iItem]} onChange={createHandleItemChange(iItem)} mdxType="Checkbox" />
           </div>)}
       </>;
      }}
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node="?node-id=9262:47442" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="?node-id=9262:47457" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "вложенные-checkbox"
    }}>{`Вложенные Checkbox`}</h3>
    <Playground __position={5} __code={'() => {\n  const [itemsChecked, setItemsChecked] = useState([false, false, false])\n  const itemsLabels = [\n    \'Вариант номер один\',\n    \'Вариант номер два\',\n    \'Вариант номер три\',\n  ]\n  const totalChecked = itemsChecked.every(v => v)\n  const totalIndeterminate = itemsChecked.some(v => v) && !totalChecked\n  const handleTotalChange = () => {\n    totalChecked || totalIndeterminate\n      ? setItemsChecked([false, false, false])\n      : setItemsChecked([true, true, true])\n  }\n  const createHandleItemChange = i => () => {\n    setItemsChecked(itemsChecked =>\n      itemsChecked.map((item, itemI) => (i === itemI ? !item : item)),\n    )\n  }\n  return (\n    <div className=\"page-space\">\n      <div>\n        <Checkbox\n          checked={totalChecked}\n          indeterminate={totalIndeterminate}\n          label=\"Варианты номер один, два и три\"\n          onChange={handleTotalChange}\n        />\n      </div>\n      {itemsChecked.map((item, itemI) => (\n        <div style={{ paddingLeft: \'10px\' }} key={itemI}>\n          <Checkbox\n            checked={item}\n            label={itemsLabels[itemI]}\n            onChange={createHandleItemChange(itemI)}\n          />\n        </div>\n      ))}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [itemsChecked, setItemsChecked] = useState([false, false, false]);
        const itemsLabels = ['Вариант номер один', 'Вариант номер два', 'Вариант номер три'];
        const totalChecked = itemsChecked.every(v => v);
        const totalIndeterminate = itemsChecked.some(v => v) && !totalChecked;

        const handleTotalChange = () => {
          totalChecked || totalIndeterminate ? setItemsChecked([false, false, false]) : setItemsChecked([true, true, true]);
        };

        const createHandleItemChange = i => () => {
          setItemsChecked(itemsChecked => itemsChecked.map((item, itemI) => i === itemI ? !item : item));
        };

        return <div className="page-space">
         <div>
           <Checkbox checked={totalChecked} indeterminate={totalIndeterminate} label="Варианты номер один, два и три" onChange={handleTotalChange} mdxType="Checkbox" />
         </div>
         {itemsChecked.map((item, itemI) => <div style={{
            paddingLeft: '10px'
          }} key={itemI}>
             <Checkbox checked={item} label={itemsLabels[itemI]} onChange={createHandleItemChange(itemI)} mdxType="Checkbox" />
           </div>)}
       </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "неактивный-checkbox"
    }}>{`Неактивный Checkbox`}</h3>
    <Playground __position={6} __code={'<Checkbox inactive={true} label={\'Вариант, который нельзя выбрать\'} />'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Checkbox inactive={true} label={'Вариант, который нельзя выбрать'} mdxType="Checkbox" />
    </Playground>
    <h3 {...{
      "id": "замена-иконки"
    }}>{`Замена иконки`}</h3>
    <p>{`Для использования своей иконки чекбокса передайте в свойство `}<inlineCode parentName="p">{`checkboxIcon`}</inlineCode>{` компонент иконки.
Иконка отображается только когда чекбокс выбран.`}</p>
    <Playground __position={7} __code={'() => {\n  const [checked, setChecked] = useState(false)\n  return (\n    <Checkbox\n      checkboxIcon={\n        <Icon size={IconSize.small} icon={IconNavigationLocation} />\n      }\n      checked={checked}\n      onChange={setChecked}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [checked, setChecked] = useState(false);
        return <Checkbox checkboxIcon={<Icon size={IconSize.small} icon={IconNavigationLocation} mdxType="Icon" />} checked={checked} onChange={setChecked} mdxType="Checkbox" />;
      }}
    </Playground>
    <h3 {...{
      "id": "checkboxgroup"
    }}>{`CheckboxGroup`}</h3>
    <p>{`Для задания опций передайте в проп `}<inlineCode parentName="p">{`options`}</inlineCode>{` массив объектов со свойствами `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`label`}</inlineCode>{`. Остальные свойства прокидываются компоненту `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{`.`}</p>
    <p>{`Существует как контролируемый вариант использования, так и неконтролируемый. Для контроля над текущим значением передайте пропы `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode></p>
    <Playground __position={8} __code={'() => {\n  const options = useMemo(\n    () => [\n      {\n        label: \'Checkbox #1\',\n        value: 1,\n      },\n      {\n        label: \'Checkbox #2\',\n        value: 2,\n      },\n      {\n        label: \'Checkbox #3\',\n        value: 3,\n        inactive: true,\n      },\n    ],\n    [],\n  )\n  const [value, setValue] = useState([options[0]])\n  return (\n    <div style={{ display: \'flex\' }}>\n      <CheckboxGroup options={options} value={value} onChange={setValue} />\n      <CheckboxGroup\n        style={{ marginLeft: 24 }}\n        defaultValue={[options[0]]}\n        options={options}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const options = useMemo(() => [{
          label: 'Checkbox #1',
          value: 1
        }, {
          label: 'Checkbox #2',
          value: 2
        }, {
          label: 'Checkbox #3',
          value: 3,
          inactive: true
        }], []);
        const [value, setValue] = useState([options[0]]);
        return <div style={{
          display: 'flex'
        }}>
    <CheckboxGroup options={options} value={value} onChange={setValue} mdxType="CheckboxGroup" />
    <CheckboxGroup style={{
            marginLeft: 24
          }} defaultValue={[options[0]]} options={options} mdxType="CheckboxGroup" />
  </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "размер-чекбокса"
    }}>{`Размер чекбокса`}</h3>
    <p>{`У чекбокса есть три размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Чекбокс с размером `}<inlineCode parentName="p">{`medium`}</inlineCode>{` или `}<inlineCode parentName="p">{`large`}</inlineCode>{` рекомендуется использовать без лейбла.`}</p>
    <Playground __position={9} __code={'() => {\n  const [checked, setChecked] = useState(false)\n  return (\n    <div style={{ display: \'grid\', gridGap: 16 }}>\n      <Checkbox label={\'Small\'} checked={checked} onChange={setChecked} />\n      <Checkbox\n        size={CheckboxSize.medium}\n        checked={checked}\n        onChange={setChecked}\n      />\n      <Checkbox\n        size={CheckboxSize.large}\n        checked={checked}\n        onChange={setChecked}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [checked, setChecked] = useState(false);
        return <div style={{
          display: 'grid',
          gridGap: 16
        }}>
       <Checkbox label={'Small'} checked={checked} onChange={setChecked} mdxType="Checkbox" />
       <Checkbox size={CheckboxSize.medium} checked={checked} onChange={setChecked} mdxType="Checkbox" />
       <Checkbox size={CheckboxSize.large} checked={checked} onChange={setChecked} mdxType="Checkbox" />
   </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Checkbox} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на элемент `}<inlineCode parentName="p">{`input`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-checkboxgroup"
    }}>{`Пропы CheckboxGroup`}</h2>
    <Props of={CheckboxGroup} mdxType="Props" />
    <h2 {...{
      "id": "css-классы"
    }}>{`CSS классы`}</h2>
    <p>{`Вы можете использовать данный компонент без библиотеки компонентов, а лишь подключив css стили. Ниже приведен пример использования. Компоненты сверстаны по методологии `}<a parentName="p" {...{
        "href": "https://bem.info/"
      }}>{`BEM`}</a>{`: миксуя необходимые модификаторы вы можете задать компоненту определенное состояние.`}</p>
    <p>{`Класс блока: `}<inlineCode parentName="p">{`hse-Checkbox`}</inlineCode></p>
    <h3 {...{
      "id": "модификаторы"
    }}>{`Модификаторы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Модификатор`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_radio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Превращает checkbox в radio`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_inactive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает checkbox неактивным`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_checked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Переводит checkbox в состояние checked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_indeterminate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Переводит checkbox в состояние indeterminate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_focused`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Переводит checkbox в состояние focused`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_size_small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Маленький размер checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_size_medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Средний размер checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox_size_large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Большой размер checkbox`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "элементы"
    }}>{`Элементы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox__input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Input типа checkbox или radio`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox__icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Иконка или кастомный checkbox или radio`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Checkbox__label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Текст справа от checkbox`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <Playground __position={12} __code={'<label className=\"hse-Checkbox hse-Checkbox_checked hse-Checkbox_size_medium\">\n  <input type={\'checkbox\'} className=\"hse-Checkbox__input\" checked={true} />\n  <div className=\"hse-BaseCheckboxIcon hse-Checkbox__icon hse-BaseCheckboxIcon_checked\">\n    <div className=\"hse-BaseCheckboxIcon__icon hse-BaseCheckboxIcon__icon_visible\">\n      <svg\n        width=\"1em\"\n        height=\"1em\"\n        viewBox=\"0 0 24 24\"\n        fill=\"none\"\n        xmlns=\"http://www.w3.org/2000/svg\"\n      >\n        <path\n          fillRule=\"evenodd\"\n          clipRule=\"evenodd\"\n          d=\"M8.79508 15.8749L4.62508 11.7049L3.20508 13.1149L8.79508 18.7049L20.7951 6.70492L19.3851 5.29492L8.79508 15.8749Z\"\n          fill=\"currentColor\"\n        />\n      </svg>\n    </div>\n  </div>\n  <div className=\"hse-Checkbox__label\">Checkbox</div>\n</label>'} __scope={{
      props,
      DefaultLayout,
      useState,
      useMemo,
      Checkbox,
      CheckboxSize,
      CheckboxGroup,
      CheckboxGroupOption,
      Icon,
      IconNavigationLocation,
      IconSize,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <label className='hse-Checkbox hse-Checkbox_checked hse-Checkbox_size_medium'>
      <input type={'checkbox'} className='hse-Checkbox__input' checked={true} />
      <div className='hse-BaseCheckboxIcon hse-Checkbox__icon hse-BaseCheckboxIcon_checked'>
        <div className='hse-BaseCheckboxIcon__icon hse-BaseCheckboxIcon__icon_visible'>
			    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			      <path fillRule="evenodd" clipRule="evenodd" d="M8.79508 15.8749L4.62508 11.7049L3.20508 13.1149L8.79508 18.7049L20.7951 6.70492L19.3851 5.29492L8.79508 15.8749Z" fill="currentColor" />
			    </svg>
        </div>
      </div>
      <div className='hse-Checkbox__label'>
        Checkbox
      </div>
    </label>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      